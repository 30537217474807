import * as Sentry from "@sentry/gatsby"

import {
  isOldChromeVersion,
  isPreviewBranch,
  isProductionBuild,
} from "./src/config/sentryConfig"

Sentry.init({
  dsn: "https://fa4ded1ea765453fb269d4a4f0cebca1@o414062.ingest.sentry.io/5302920",
  release: process.env.COMMIT_SHA,
  environment: (() => {
    if (isProductionBuild() && !isPreviewBranch()) {
      return "production"
    }
    return "preview"
  })(),
  enabled: isProductionBuild(),

  // This enables automatic performance tracking and Core Web Vitals tracking.
  tracesSampleRate: 0.01,
  beforeSend: (event: Sentry.Event) => {
    if (isOldChromeVersion(event.request?.headers?.["User-Agent"] ?? "")) {
      // Don't send events from old versions of Chrome on mobile devices.
      // These are not filtered out by Sentry's legacy browsers feature.
      return null
    }
    return event
  },
})
