function isOldChromeVersion(userAgent: string): boolean {
  const match = userAgent.match(/Chrome\/(\d+)/)
  return (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Mobile") > -1 &&
    match !== null &&
    parseInt(match[1], 10) < 62
  )
}

const isProductionBuild = () =>
  ["production"].indexOf(process.env.NODE_ENV ?? "") !== -1

const isPreviewBranch = () =>
  typeof window !== "undefined" && window.location.hostname.includes("preview")

export { isOldChromeVersion, isProductionBuild, isPreviewBranch }
