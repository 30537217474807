import { Auth } from "@aws-amplify/auth/lib/Auth"
import * as Sentry from "@sentry/gatsby"
import { camelizeKeys, decamelize, decamelizeKeys } from "humps"
import { EMPTY, Observable, firstValueFrom, from, of } from "rxjs"
import { AjaxError, ajax } from "rxjs/ajax"
import { catchError, map, mergeMap } from "rxjs/operators"

import { ensureLoggedOut } from "utils/global-state"

function logError(response, method: string, url: string) {
  if (
    (response.error.status != 0 || response.error.status === undefined) &&
    // We use status 422 for `QuoteCannotBeBooked`, i.e. a class of exceptions where it's not a bug,
    // it's just that e.g. the pre-booking delay expired while they were booking. So we don't need
    // to log these.
    response.error.status != 422
  ) {
    Auth.currentUserInfo().then((user) => {
      Sentry.setUser({ username: user?.username })
      Sentry.captureException(
        new Error(`${response.error.status} error on ${method} ${url}`),
        {
          contexts: {
            details: {
              statusCode: response.error.status,
              response: response.error.response,
              request: response.error.request,
              message: response.error.message,
              name: response.error.name,
            },
          },
        }
      )
    })
  }
}

export function fetchApi(
  url: string,
  method = "GET",
  body = {}
): Observable<Record<string, any>> {
  const headers: any = {}
  if (method === "POST") headers["Content-Type"] = "application/json"
  // Roundtrip body through JSON *before* we decamelize keys. This makes sure any Temporal objects
  // get converted into strings before decamelizeKeys mangles them.
  body = decamelizeKeys(JSON.parse(JSON.stringify(body)), { separator: "_" })
  return ajax({ url, method, body, headers }).pipe(
    catchError((error) => of({ response: { error } })),
    map((x) => {
      const processedResponse = camelizeKeys(
        x?.response as Record<string, any>
      ) as any
      if (processedResponse?.error) {
        logError(processedResponse, method, url)
      }
      return processedResponse
    })
  )
}

export function fetchWithAuth(
  url: string,
  method = "GET",
  body = {},
  authRequired = false,
  contentType: string | null = "application/json",
  shouldDecamelizeKeys = true,
  shouldCamelizeResponseKeys = true
) {
  // By default, this function will fetch the request and use JWT token if one is available.
  // When `authRequired = true`, the function will only fetch the request if a JWT token is available.
  // Otherwise, the observable will complete immediately.

  //TODO: Auth.currentUserPoolUser() will be called even if the request has been cancelled by .unsubscribe()

  // NB we call currentUserPoolUser here instead of currentUserInfo because unlike the latter, this will
  // not hide underlying network errors from us
  return from(Auth.currentUserPoolUser()).pipe(
    catchError((error) => {
      if (`${error}`.indexOf("Network error") > -1) {
        // Let the next function below know that this is just a network error that occurred while talking
        // to AWS, no need yet to go full login-page on the user
        return of({ isNetworkError: true })
      }
      return of(null)
    }),
    mergeMap((user) => {
      if (user && !user.isNetworkError) {
        return Auth.currentSession()
      } else {
        if (!user?.isNetworkError) {
          // NB `ensureLoggedOut` is an async function, and we don't wait for it.
          // Just move on with the request; in due time the UI will show to the
          // user that they have been logged out.
          ensureLoggedOut()
        }
        return authRequired ? EMPTY : of(null)
      }
    }),
    map((session: any) => {
      const headers: any = {}
      if (session)
        headers["Authorization"] = `Bearer ${session
          ?.getIdToken()
          .getJwtToken()}`
      return headers
    }),
    mergeMap((headers) => {
      if (contentType && (method === "POST" || method === "PUT")) {
        headers["Content-Type"] = contentType
      }
      if (shouldDecamelizeKeys) {
        // Roundtrip body through JSON *before* we decamelize keys. This makes sure any Temporal objects
        // get converted into strings before decamelizeKeys mangles them.
        body = decamelizeKeys(JSON.parse(JSON.stringify(body)), {
          separator: "_",
        })
      }
      return ajax({ url, method, body, headers })
    }),
    catchError((error) => of({ response: { error } })),
    map((x) => {
      let processedResponse = x?.response as Record<string, any>
      if (shouldCamelizeResponseKeys) {
        if (
          process.env.NODE_ENV == "test" &&
          processedResponse?.error &&
          processedResponse.error instanceof AjaxError
        ) {
          // rxjs AjaxErrors include the response, and msw's response objects don't take kindly
          // to being camelized. But we rarely access anything but response.error on these, so
          // don't bother camelizing.
          //
          // Don't do this in prod because (a) we don't need to, and (b) I'm not sure if it would
          // break anything.
        } else {
          processedResponse = camelizeKeys(processedResponse)
        }
      }
      if (processedResponse?.error) {
        logError(processedResponse, method, url)
      }
      return processedResponse as any
    })
  )
}

export function buildApiUrl(path: string): string {
  return `${process.env.GATSBY_API_BASE_URL}${path}`
}

export interface FetchFromAPIBaseParams {
  path: string
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH"
  authRequired?: boolean
  useAuthIfAvailable?: boolean
  body?: Record<string, any>
  contentType?: string | null
  shouldDecamelizeKeys?: boolean
  shouldCamelizeResponseKeys?: boolean
}

/**
 * @deprecated Use authFetch/plainFetch from new-fetch-utils instead.
 */
export function fetchFromAPIBase({
  path,
  method = "GET",
  authRequired = false,
  useAuthIfAvailable = true,
  body = {},
  contentType = "application/json",
  shouldDecamelizeKeys = true,
  shouldCamelizeResponseKeys = true,
}: FetchFromAPIBaseParams) {
  const url = buildApiUrl(path)
  if (useAuthIfAvailable == true) {
    return fetchWithAuth(
      url,
      method,
      body,
      authRequired,
      contentType,
      shouldDecamelizeKeys,
      shouldCamelizeResponseKeys
    )
  } else {
    return fetchApi(url, method, body)
  }
}

/**
 * @deprecated Use authFetch/plainFetch from new-fetch-utils instead.
 */
export function asyncFetchFromAPIBase(params: FetchFromAPIBaseParams) {
  const observable = fetchFromAPIBase(params)
  return firstValueFrom(observable)
}

// 2022-09-30 - `generateErrorMessage` has been renamed to `parseErrorMessage`, which see

export default async function getJwtToken(): Promise<string | undefined> {
  // Get the JWT token for the current user
  if (await Auth.currentUserInfo()) {
    const session = await Auth.currentSession()
    return session?.getIdToken().getJwtToken()
  } else {
    return undefined
  }
}

/**
 * Takes a key/value mapping and returns a query string ready for use in a URL.
 * The returned string either begins with a "?" or is empty. Any key whose
 * value is null or undefined is skipped.
 *
 * Keys are converted to snake_case. Both keys are values are encoded, so they
 * may contain & or ? characters; the strings in the supplied `query` object
 * should not be already escaped, else they will end up double-escaped.
 */
export const getQueryString = (query: any): string => {
  const params = new URLSearchParams()
  let empty = true
  for (const [key, value] of Object.entries(query)) {
    if (value == null) {
      continue
    }
    const valueElements = Array.isArray(value) ? value : [value]
    for (const elem of valueElements) {
      params.append(decamelize(key), elem.toString())
      empty = false
    }
  }
  if (empty) {
    return ""
  }
  return "?" + params.toString()
}
