// TIMEZONES: a novel
//
// All dates and times displayed to the user on our website must be timezone-aware. We don't leave
// anything up to the browser's or the server's local time.
//
//
// Generally we want all times to be displayed in the timezone of the associated location. So for
// instance stop times are displayed in the timezone of the stop's location, and shift times are
// displayed in the timezone of the shift's hub. The `toLocalTime` function below can be used for
// this.
//
// The backend API sends all datetimes to the frontend as ISO strings that include a timezone
// marker. The timezone in those strings is typically UTC, but ultimately it doesn't matter which
// timezone it is, it doesn't need to match the timezone used to display the datetime on the
// frontend -- it just serves to anchor the time to an exact moment.
//
// The API also gives us the named timezone of each location (e.g. "Europe/London"), so for
// timestamps that relate to a location, we don't need to hardcode the timezone.
//
//
// However! There are several contexts where we have to display a datetime that is not tied to a
// specific location:
//
// <> Times that are related to a trip, but not to a specific stop
//
//    This includes the timestamps for driver messages, passenger messages, stops cancellations. In
//    most cases these don't relate to a specific stop, but they are always displayed within the
//    context of a specific trip.
//
//    For these datetimes we use the timezone of the 1st stop on the trip. This will allow us to
//    operate in multiple markets, as long as we don't have timezone-crossing trips. Once we do have
//    those, we'll need something smarter.
//
// <> Times used in search queries
//
//    When you're on the trip admins page and looking at trips from, say, 2023-03-27, what timezone
//    should we use to interpret that date? The search is not associated with any location or trip.
//    The same applies to the dates that customers select when searching for tickets.
//
//    Eventually these queries should be done using naive datetimes, since we want to select trips
//    based on their departure time in each trip's respective timezone. We intend to do that, but it
//    will require backend changes, and YAGNI for now.
//
//    So for now we hardcode queries to use the timezone named below in `getDefaultTimezone`. Once
//    we operate in multiple timezones, we can revisit this.
//
// <> Views that potentially span multiple timezones (other than trip views)
//
//    When we display a calendar timeline view of driver activities, we need to pick a single
//    timezone for the whole view -- we don't want for instance to have events that are shown as
//    aligned in the timeline chart but that don't actually start at the same time.
//
//    For such views at the moment we fall back to the timezone named in `getDefaultTimezone`. Once
//    we move to multi-timezone markets we might want to have a GUi widget that lets the user picks
//    the timezone they want to use. Maybe it could default to their browser's timezone.
//
// <> Other times
//
//    We have a few other places where we display times that are not tied to a location or to a
//    trip, and are not timezone-less queries. For instance, the timestamps shown in a customer's
//    credit history modal.
//
//    For now these too are hardcoded to the timezone returned by `getDefaultTimezone`. This should
//    work in a multi-market setup as long as each market has only a single timezone. As with the
//    other scenarios just described above, once we expand into a multi-timezone market, we'll need
//    to rethink this further.

export const getDefaultTimezone = () =>
  // Pages & components that don't currently have a smart way of assigning a timezone to a timestamp
  // (the vehicles page, the search form, the driver rota timeline, etc) can use the site-wide
  // timezone. This should work as long as we're in single-timezone markets.
  //
  // Eventually this could read from global state, environment variables, etc. As explained above,
  // this should be good enough for a multi-market setup, as long as each market is in a single
  // timezone.
  //
  // Once we enter multi-timezone markets, this global variable will need to go away, and every use
  // of it will need to be made smarter so that it can pick a sensible timezone for every time
  // displayed. And that's partly the point of this function -- every use of it marks a piece of
  // code where the code will need to pick a timezone.
  "Europe/London"

// We can infer a timezone from any of these
// Eg. Location, LocationTime, TimeOffBooking, Trip, TripInfo
type LocationLike = {
  timezone: string
}
type LocationTimeLike = {
  location: LocationLike
}

export type TimezoneContext =
  | LocationLike
  | LocationTimeLike
  | LocationTimeLike[]
  | { origin: LocationTimeLike }
  | { route?: LocationTimeLike[] | null }
  | { timezone: string }
  | string

export const getTimezoneFromContext = (context: TimezoneContext): string => {
  if (!context || typeof context == "string") {
    // Placate typescript: we just checked the type
    // (Weirdly this fails only in non-strict mode; hopefully if you're reading this in a better future where the entire
    // codebase is strict mode and we're not running typescript-strict-plugin, see if you can get rid of the cast!)
    return validateTimezoneString(context as string)
  }

  // TripInfo object
  if ("route" in context) {
    if (!context.route) {
      throw new Error(
        "The given TripInfo object doesn't have route information"
      )
    }
    context = context.route as LocationTimeLike[]
  }

  // List of stops: take the 1st time
  if (Array.isArray(context)) {
    if (context.length == 0) {
      throw new Error("Cannot use empty array as context")
    }
    context = context[0] as LocationTimeLike
  }

  // Trip: use its origin
  if ("origin" in context) {
    context = context.origin as LocationTimeLike
  }

  // LocationTime: take its Location
  if ("location" in context) {
    context = context.location as LocationLike
  }

  // @ts-expect-error: by now we've whittled it down to sth that has a timezone attribute
  return validateTimezoneString(context.timezone)
}

const validateTimezoneString = (timezone: string): string => {
  if (!timezone) {
    throw new Error(`Missing timezone: ${timezone == "" ? '""' : timezone}`)
  }
  return timezone
}
